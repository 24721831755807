import { AUTHENS } from '@/commons/constants'
import { authFire } from '@/firebaseConfig'
import { onAuthStateChanged } from 'firebase/auth'

export const checkAuthState = async () => {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(authFire, (user) => {
      if (user) {
        localStorage.setItem(AUTHENS.AUTHEN_DATA, JSON.stringify(user))
        resolve(user)
      } else {
        localStorage.removeItem('user')
        resolve(null)
      }
    })
  })
}
